/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { elemWidth, elemHeight } from 'utilities/elem.js';
import { doTimeout, clearTimeouts } from 'utilities/timeout-utils.js';

(function (W) {
  return W.plugin('cropFill', (video, options) => {
    // Get the video's aspect ratio ASAP.
    // We need it for all resizing calculations.
    let videoAspect = null;
    video.hasData(() => {
      const still = W.Video.stillAsset(video._mediaData);
      return (videoAspect = still.width / still.height);
    });

    // To position this properly, the target needs to have a position from
    // which we can set offsets.
    const target = video.container.parentNode;
    if (target !== document.body && !/absolute|fixed|relative/.test(target.style.position)) {
      target.style.position = 'relative';
      target.style.overflow = 'hidden';
    }
    video.container.style.position = 'absolute';

    // Resize the video and reposition it so it's properly cropped
    const resize = function () {
      const targetWidth = elemWidth(target);
      const targetHeight = elemHeight(target);
      const targetAspect = targetWidth / targetHeight;

      if (targetAspect > videoAspect) {
        // target is wider than video, so match width and crop top/bottom
        video.width(targetWidth, { constrain: true });
        const newTop = -(video.height() - targetHeight) / 2;
        video.container.style.top = `${Math.round(newTop)}px`;
        return (video.container.style.left = '0px');
      }
      // target is taller than video, so match height and crop left/right
      video.height(targetHeight, { constrain: true });
      const newLeft = -(video.width() - targetWidth) / 2;
      video.container.style.left = `${Math.round(newLeft)}px`;
      return (video.container.style.top = '0px');
    };

    const debounceResize = () => doTimeout(`${video.uuid}.cropFill.resize`, resize, 50);

    // Poll on the parent dimensions every 500ms
    let lastWidth = elemWidth(target);
    let lastHeight = elemHeight(target);
    let watchTarget = function () {
      const widthNow = elemWidth(target);
      const heightNow = elemHeight(target);
      if (lastWidth !== widthNow) {
        resize();
        lastWidth = widthNow;
      } else if (lastHeight !== heightNow) {
        resize();
        lastHeight = heightNow;
      }
      return doTimeout(`${video.uuid}.cropFill.watchTarget`, watchTarget, 500);
    };
    const unwatchTarget = () => clearTimeouts(`${video.uuid}.cropFill.watchTarget`);

    // Resize if the dimensions of the container or the video change
    watchTarget();
    video.bind('widthchange', debounceResize);
    video.bind('heightchange', debounceResize);

    // Resize ASAP
    video.hasData(resize);

    return {
      resize,
      watch: watchTarget,
      unwatch: unwatchTarget,
    };
  });
})(window.Wistia);
